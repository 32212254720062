'use client';

import React, { MouseEventHandler, useState } from 'react';
import Link from 'next/link';
import Image from 'next-image-export-optimizer';
import { IconMenu2 } from '@tabler/icons-react';
import logoIcon from '../../../public/images/logo-icon.png';
import { useClickOutside } from '@mantine/hooks';
import GoogleTagManager from '@dr-pam/common-components/Components/Page/GoogleTagManager';

export type HeaderProps = {
	educationHubLoginUrl: string;
	gtmId: string;
};

export default function Header(props: HeaderProps) {
	const { educationHubLoginUrl, gtmId } = props;

	const [navEl, setNavEl] = useState<HTMLElement | null>(null);
	const [menuEl, setMenuEl] = useState<HTMLElement | null>(null);

	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	const handleMenuClicked: MouseEventHandler<HTMLAnchorElement> = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsMenuOpen(!isMenuOpen);
	};

	useClickOutside(() => setIsMenuOpen(false), null, [navEl, menuEl]);

	return (
		<>
			<GoogleTagManager gtmId={gtmId} />
			<header className="dark-bg">
				<Link href="/" className="header-logo ndc-logo">
					<Image src={logoIcon} alt=""></Image>
					<p>
						the ndc <br></br>institute
					</p>
				</Link>
				<nav
					ref={setNavEl}
					className={`switch-link-underline-direction ${isMenuOpen ? 'menu-open' : ''}`}
					onClick={handleMenuClicked}
				>
					<Link href="/courses">ndc courses</Link>
					<Link href="/about-ndc">about the institute</Link>
					<Link href="/code-of-ethics">code of ethics</Link>
					<Link href="/find-an-ndc-practitioner">find an NDC practitioner</Link>
					<Link href="/free-resources">free resources</Link>
					<Link href={educationHubLoginUrl}>login</Link>
				</nav>
				<Link ref={setMenuEl} href="#" className="menu-toggle" onClick={handleMenuClicked}>
					<IconMenu2 />
				</Link>
			</header>
		</>
	);
}
