import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/builds/dr-pam/ndc-institute/node_modules/@dr-pam/common-components/Components/Auth/AuthProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/dr-pam/ndc-institute/node_modules/next-image-export-optimizer/dist/ExportedImage.js");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/ndc-institute/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/ndc-institute/public/images/dr_pam-logo-large_white.png");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/ndc-institute/public/images/logo-icon.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/dr-pam/ndc-institute/src/components/layout/Header.tsx");
;
import(/* webpackMode: "eager" */ "/builds/dr-pam/ndc-institute/src/styles/main.scss");
